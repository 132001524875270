import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Slider } from '@mui/material';

import Logout from '@/components/utility/buttons/Logout';
import useAccount from '@/hooks/useAccount';
import { State } from '@/models/ReducerModels';

import ChoosePlaylists from '../components/onboarding/ChoosePlaylists';
import ConnectSpotifyAccount from '../components/onboarding/ConnectSpotifyAccount';
import ReadyToGo from '../components/onboarding/ReadyToGo';
import SettingUp from '../components/onboarding/SettingUp';
import UserInfo from '../components/onboarding/UserInfo';
import VerifyEmail from '../components/onboarding/VerifyEmail';
import WalletSetup from '../components/onboarding/WalletSetup';
import Loading from '../components/utility/Loading';
import OnboardingContext from '../contexts/OnboardingContext';

import { onboardingReducer } from '@/reducers/onboardingReducer';

export default function OnboardingPage() {
  document.title = 'Onboarding';
  const { unhurdUser } = useAccount();
  const location = useLocation();

  const { user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const initialState = {
    page: undefined,
  };

  const [state, dispatch] = useReducer(onboardingReducer, initialState as State);

  const configurePageNumber = useCallback(() => {
    if (!unhurdUser) return 0;
    if (unhurdUser.accountConfiguration === null) {
      return 1;
    }
    if (unhurdUser.accountConfiguration.onboardingStage === 0) {
      return 2;
    }
    if (unhurdUser.accountConfiguration.onboardingStage === 4) {
      return unhurdUser.accountConfiguration.onboardingStage;
    }
    return unhurdUser.accountConfiguration.onboardingStage + 1;
  }, [unhurdUser]);

  useEffect(() => {
    setIsLoading(true);
    if (!user || !user.sub) return;
    if (!unhurdUser) {
      if (!user?.email_verified) {
        dispatch({
          type: 'SET PAGE',
          payload: { page: 0 },
        });
      } else {
        dispatch({
          type: 'SET PAGE',
          payload: { page: 1 },
        });
      }
      return setIsLoading(false);
    }
    dispatch({
      type: 'SET PAGE',
      payload: { page: configurePageNumber() },
    });

    if (unhurdUser && unhurdUser.accountConfiguration.onboardingStage === 5) {
      navigate('/dashboard');
    }

    setIsLoading(false);
  }, [user, unhurdUser, navigate, configurePageNumber]);

  const isStepper = useMemo(() => {
    if (!state.page) return false;
    if (state.page && state.page > 0 && state.page < 5) {
      return true;
    }
  }, [state.page]);

  return (
    <OnboardingContext.Provider value={{ state, dispatch }}>
      {!isLoading ? (
        <div className="onboarding-page">
          <div className="add-playlist-close-btn">
            <Logout />
          </div>
          {isStepper && (
            <div className="max-w200 m-auto mt48">
              <p className="text-blue">
                Step {state.page} out of {4}
              </p>
              {state.page && <Slider value={(100 / 4) * state.page}></Slider>}
            </div>
          )}
          {state.page === 0 && <VerifyEmail />}
          {/* Tell us about yourself */}
          {state.page === 1 && location.state !== 'goToSpotifyConnect' && <UserInfo />}
          {/* Connect Spotify Account */}
          {(state.page === 2 || (state.page === 1 && location.state === 'goToSpotifyConnect')) && (
            <ConnectSpotifyAccount />
          )}
          {/* Choose Your Playlists */}
          {state.page === 3 && <ChoosePlaylists />}
          {/* Set Up Wallet */}
          {state.page === 4 && <WalletSetup />}
          {/* Setting you up */}
          {state.page === 5 && <SettingUp />}
          {/* Ready to go */}
          {state.page === 6 && <ReadyToGo />}
        </div>
      ) : (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
    </OnboardingContext.Provider>
  );
}

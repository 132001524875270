import { createContext } from 'react';

export type OnboardingStateModel = {
  page?: number;
  open?: boolean;
};

export type OnboardingDispatchModel = {
  type?: string;
  payload?: {
    page?: number;
  };
};

export type OnboardingContextModel = {
  state: OnboardingStateModel;
  dispatch: (value: OnboardingDispatchModel) => void;
};

export const OnboardingContext = createContext<OnboardingContextModel>({} as OnboardingContextModel);

export default OnboardingContext;
